import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FormGroup, FormControl, Button, FormLabel, FloatingLabel } from "react-bootstrap";

const SubscriberForm = (props) => {
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("You have enter an invalid email address")
            .required("Required"),
        zipcode: Yup.string()
            .required("Required"),
    });
    return (
        <div className="form-wrapper">
            <Formik {...props} validationSchema={validationSchema}>
                <Form className="form">
                    <FormGroup className="form-input">
                        <FloatingLabel controlId="floatingInputGrid" label="Email">
                            <Field name="email" type="text" placeholder="Email"
                                className="form-control" />
                            <ErrorMessage
                                name="email"
                                className="d-block invalid-feedback"
                                component="span"
                            />
                        </FloatingLabel>
                    </FormGroup>
                    <FormGroup className="form-input">
                        <FloatingLabel controlId="floatingInputGrid" label="Zipcode">
                            <Field name="zipcode" type="text" placeholder="Zip Code"
                                className="form-control" />
                            <ErrorMessage
                                name="zipcode"
                                className="d-block invalid-feedback"
                                component="span"
                            />
                        </FloatingLabel>
                    </FormGroup>
                    <div className="d-grid gap-2">
                        <Button variant="dark" size="lg"
                            block="block" type="submit" className="form-input" >
                            Subscribe
                        </Button>
                    </div>
                </Form>
            </Formik>
        </div>
    );
};

export default SubscriberForm;
