import React, { useState, useEffect } from "react";
import Groundhogg from "../Images/groundhogg.png"

const GroundhoggHeader = () => {

    return (
        <div className="header-container">
            <img src={Groundhogg}></img>
            <div className="header-title">
                Groundhogg
            </div>
            <div className="header-text">
                Subscribe to the best daily forecast newsletter
            </div>
        </div>
    )
}

export default GroundhoggHeader
