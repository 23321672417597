import React from "react";
import { Nav, Navbar, Container, Row, Col, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import CreateSubscriber from "./Components/createSubscriber";
import GroundhoggHeader from "./Components/groundhoggHeader"

const App = () => {

  return (
    <div className="App">
      <div className="subscribe-container">
        <div className="subscribe-image">
          <GroundhoggHeader />
        </div>
        <div className="subscriber-list-body ">
          <CreateSubscriber />
        </div>
      </div>
    </div>
  );
};

export default App;
