import React, { useState, useEffect } from "react";
import axios from 'axios';
import SubscriberForm from "./subscriberForm";

// CreateSubscriber Component
const CreateSubscriber = (props) => {
    const [formValues, setFormValues] =
        useState({ email: '', zipcode: '' })

    const onSubmit = (subscriberObject, { resetForm }) => {
        console.log("submit", subscriberObject)
        axios.post(
            'http://localhost:4000/subscriber/create-subscriber',
            subscriberObject)
            .then(res => {
                if (res.status === 200) {
                    resetForm({ email: '', zipcode: '' })
                } else
                    console.log("rejected")

                Promise.reject()
            })
            .catch(err => alert(`Something went wrong\n ${err}`))
    }

    return (
        <SubscriberForm
            initialValues={formValues}
            onSubmit={onSubmit}
            enableReinitialize>
        </SubscriberForm>
    )
}

export default CreateSubscriber
